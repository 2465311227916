.scrollable-content {
  scrollbar-width: thin;
  scrollbar-color: #d3d3d3 #888;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
